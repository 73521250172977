import React from "react";
import EnConstructionImg from "../imgs/UnderConstruction.png"
import Project1 from "../imgs/ProjetBoulangerie.png"

const HomeFRComponent = () => {
    return(
        <div className="HomeComponentWrapper">
            <div className="HomeComponentWrapper2">
                <div className="introduction">
                    <h2>
                        Welcome !
                    </h2>
                    <p>
                    My name is Nathaniel Leblanc, and I’m a Computer Science undergraduate student. 
                    Outside of my studies, I dedicate my free time to front-end programming, particularly 
                    using CSS, HTML, JavaScript, and React. Through my academic journey, I've also gained 
                    experience in back-end development and database management. Scroll down to explore my various projects.
                    </p>
                </div>
                <div className="arrowWrapper">
                    <a className="arrow" href="#ProjectsWrapper">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div className="ProjectsWrapper" id="ProjectsWrapper">
                    <div className="box">
                        <div className="imgWrapper">
                            <a href="http://nathanielleblanc.com-chezmatante.s3-website.ca-central-1.amazonaws.com/"><img src={Project1}></img></a>
                        </div>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeFRComponent;