import React from "react";
import EnConstructionImg from "../imgs/En construction.png"
import Project1 from "../imgs/ProjetBoulangerie.png"

const HomeFRComponent = () => {
    return(
        <div className="HomeComponentWrapper">
            <div className="HomeComponentWrapper2">
                <div className="introduction">
                    <h2>
                        Bienvenue !
                    </h2>
                    <p>
                    Je m'appelle Nathaniel Leblanc, étudiant en baccalauréat en informatique.
                    En dehors de mes études, je consacre mon temps libre à la programmation front-end,
                    notamment avec les technologies CSS, HTML, JavaScript et React. Grâce à mon parcours,
                    j'ai également acquis de l'expérience en développement back-end et en gestion de bases de données.
                    Faites défiler la page pour découvrir mes différents projets.
                    </p>
                </div>
                <div className="arrowWrapper">
                    <a className="arrow" href="#ProjectsWrapper">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
                <div className="shadowdown"></div>
                <div className="ProjectsWrapper" id="ProjectsWrapper">
                    <div className="box">
                        <div className="imgWrapper">
                            <a href="http://nathanielleblanc.com-chezmatante.s3-website.ca-central-1.amazonaws.com/"><img src={Project1}></img></a>
                        </div>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                    <div className="imgWrapper">
                        <img src={EnConstructionImg}></img>
                    </div>
                </div>
                <div className="shadowup"></div>
            </div>
        </div>
    );
};

export default HomeFRComponent;