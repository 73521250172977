import React, { useState } from "react";
import ChooseLanguageComponent from "../components/ChooseLanguageComponent";
import HomeFRComponent from "../components/HomeFRComponent";
import HomeENComponent from "../components/HomeEnComponent";

const HomePage = () =>{
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    return(
        <div className="HomePageWrapper">
            <ChooseLanguageComponent onLanguageChoose={setSelectedLanguage} />
            {selectedLanguage === "FR" && <HomeFRComponent/>}
            {selectedLanguage === "EN" && <HomeENComponent/>}
        </div>         
    );
};

export default HomePage