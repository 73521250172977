import React, { useState, useEffect } from "react";

const ChooseLanguageComponent = ({onLanguageChoose}) => {
    const [isLanguageChoosen, setIsLanguageChoosen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isEnglish, setIsEnglish] = useState(null);

    const HandleQuitChooseLanguage = () => {
        setIsLanguageChoosen(true);
    };

    const HandleChooseEnglish = () => {
        onLanguageChoose("EN")
        setIsEnglish(true);
        HandleQuitChooseLanguage();
    };

    const HandleChooseFrench = () => {
        onLanguageChoose("FR")
        setIsEnglish(false);
        HandleQuitChooseLanguage();
    };

    useEffect(() => {
        if (isLanguageChoosen) {
          const timer = setTimeout(() => setIsVisible(false), 2000);
          return () => clearTimeout(timer);
        }
    }, [isLanguageChoosen]);

    return (
        <>    
            {isVisible && (
                <div className={`ChooseLanguageComponentWrapper ${isLanguageChoosen ? (isEnglish ? "QuitChooseLanguageLeft" : "QuitChooseLanguageRight") : ""}`}>
                    <div className="stars"></div>
                    <div className="row">
                        <div className="column">
                            <div className="txt glow-on-hover">
                                <div className="left">
                                    <button className="btn" onClick={() => { HandleQuitChooseLanguage(); HandleChooseEnglish(); }}>EN</button>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="line">
                                <div>।</div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="lineH">
                                <div>━</div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="txt glow-on-hover">
                                <div className="right">
                                    <button className="btn" onClick={() => { HandleQuitChooseLanguage(); HandleChooseFrench(); }}>FR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChooseLanguageComponent;

